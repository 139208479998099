<template>
  <!-- 详情上-开始 -->
  <el-row class="searchRow">
    <el-col :xs="24" :lg="24">
      <search ref="searchRef" @showComponent="showComponent" @queryEquipInfo="handleSearchEmitEquipInfor"></search>
    </el-col>
  </el-row>

  <!-- 详情上-结束 -->

  <!-- 详情主内容-开始 -->
  <!-- 非索道项目 单个探测体 开始 -->
  <template v-if="componentFlag == 'nosuodao' && sensorCount == 1">
    <el-row class="mainRow">
      <el-col class="overviewCol" :xs="24" :sm="24" :md="24" :lg="12" :xl="9" :span="9">
        <overview :sensorCount="1" ref="overViewRef" :key="1"></overview>
        <!-- <more-sensor-overview-vue ref="moreSensorOverviewRef"></more-sensor-overview-vue> -->
      </el-col>
      <el-col class="monitorCol" :xs="24" :sm="24" :md="24" :lg="12" :xl="15" :span="15">
        <monitor-result :damageObj="sensorDataList.length ? sensorDataList[0].damageObj : {}"
          :isClickSearch="isClickSearch" echartContainerClass="chartContainer" componentTitle="实时监测结果"
          ref="monitorRef"></monitor-result>
      </el-col>
      <el-col class="damageValueCol" :xs="24" :sm="24" :md="24" :lg="8" :xl="9" :span="9">
        <damage-value ref="damageValueRef"></damage-value>
      </el-col>
      <el-col class="goDistanceCol" :xs="24" :sm="24" :md="24" :lg="9" :xl="11" :span="11">
        <go-distance ref="goDistanceRef"></go-distance>
      </el-col>
      <el-col class="damageListCol" :xs="24" :sm="24" :md="24" :lg="7" :xl="4" :span="4" style="padding-right:0;">
        <damage-list componentTitle="损伤列表" ref="damageListRef"></damage-list>
      </el-col>
    </el-row>
  </template>

  <!-- 非索道项目 单个探测体 结束 -->

  <!-- 非索道项目 多个探测体 开始 -->
  <template v-if="componentFlag == 'nosuodao' && sensorCount >= 2">
    <el-row class="mainRow">
      <el-col class="moreSensorOverviewCol" :xs="24" :sm="24" :md="24" :lg="12" :xl="9" :span="9">
        <!-- <overview :sensorCount="2" ref="overViewRef" :key="1"></overview> -->
        <more-sensor-overview-vue ref="moreSensorOverviewRef"></more-sensor-overview-vue>
      </el-col>
      <el-col class="goDistanceColMoreSensor" :xs="24" :sm="24" :md="24" :lg="12" :xl="15" :span="15">
        <go-distance ref="goDistanceRef"></go-distance>
      </el-col>
      <el-col :class="['moreSensorMonitorResult', item.className]" :xs="24" :sm="24" :md="24" :lg="12" :xl="12"
        v-for="(item, index) in sensorDataList" :key="item">
        <el-row>
          <el-col :xs="24" :lg="14" class="topLeft">
            <monitor-result :echartContainerClass="item.echartContainerClass" :ref="item.monitorRef"
              :componentTitle="index == 0 ? '实时监测结果' : ''" :lineStyle="item.lineStyle" :serial="item.serial"
              :damageObj="item.damageObj" :isClickSearch="isClickSearch"></monitor-result>
          </el-col>
          <el-col :xs="24" :lg="10" class="topRight">
            <damage-list :ref="item.damageListRef" :damageObj="item.damageObj" :isClickSearch="isClickSearch"
              :pageCount="6"> </damage-list>
          </el-col>
        </el-row>
      </el-col>
      <el-col class="moreSensorDamageValue" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <!-- <more-sensor-damage-value ref="moreSensorDamageValueRef"></more-sensor-damage-value> -->
        <damage-value ref="moreSensorDamageValueRef"></damage-value>
      </el-col>
    </el-row>
  </template>

  <!-- 非索道项目 多个探测体 结束 -->

  <!-- 索道项目 开始 -->
  <template v-if="componentFlag == 'suodao'">
    <el-row class="mainRow">
      <cableway ref="cablewayRef"></cableway>
    </el-row>
  </template>

  <!-- 索道项目 结束 -->

  <!-- 详情主内容-结束 -->

  <!-- 只有管理员可以看到 -->
  <template v-if="userCode == '999999' && componentFlag == 'nosuodao' && sensorCount >= 2">
    <el-row class="testRow" style="margin-top: 20px;">
      <el-col :class="['moreSensorMonitorResult', item.className]" :xs="24" :sm="24" :md="24" :lg="12" :xl="12"
        v-for="(item, index) in testSensorDataList" :key="item">
        <el-row>
          <el-col :xs="24" :lg="14" class="topLeft">
            <test-monitor-result :echartContainerClass="item.echartContainerClass" :ref="item.monitorRef"
              :componentTitle="index == 0 ? '实时监测结果（测试）' : ''" :lineStyle="item.lineStyle" :serial="item.serial"
              :damageObj="item.damageObj" :isClickSearch="isClickSearch"></test-monitor-result>
          </el-col>
          <el-col :xs="24" :lg="10" class="topRight">
            <test-damage-list :ref="item.damageListRef" :damageObj="item.damageObj" :isClickSearch="isClickSearch"
              :pageCount="6"> </test-damage-list>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </template>
  <template v-if="userCode == '999999' && componentFlag == 'nosuodao' && sensorCount == 1">
    <el-row class="testRow" style="margin-top: 20px;">
      <el-col :xs="24" :lg="14" class="topLeft">
        <test-monitor-result :echartContainerClass="'testChartContainer'" :ref="'testMonitorRef'"
          :componentTitle="'实时监测结果（测试）'" :damageObj="testSensorDataList.length ? testSensorDataList[0].damageObj : {}"
          :isClickSearch="isClickSearch"></test-monitor-result>
      </el-col>
      <el-col :xs="24" :lg="10" class="topRight">
        <test-damage-list componentTitle="损伤列表" ref="testDamageListRef"></test-damage-list>
      </el-col>
    </el-row>
  </template>
</template>

<script>
import Search from '../components/detail/Search.vue'
import Overview from '../components/detail/Overview.vue'
import MoreSensorOverviewVue from '../components/detail/MoreSensorOverview.vue'
import MonitorResult from '../components/detail/MonitorResult.vue'
import DamageValue from '../components/detail/DamageValue.vue'
import MoreSensorDamageValue from '../components/detail/MoreSensorDamageValue.vue'
import DamageList from '../components/detail/DamageList.vue'
import GoDistance from '../components/detail/GoDistance.vue'
import Cableway from '../components/detail/Cableway.vue'
import TestMonitorResult from '../components/detail/TestMonitorResult.vue'
import TestDamageList from '../components/detail/TestDamageList.vue'
import { getItem, removeItem } from '../api'
import { signalrUrl } from '../config'
import * as $ from '../utils/jquery.min.js'
import signalr from '../utils/jquery.signalR-2.4.0.min.js'
import hubs from '../utils/hubs'
export default {
  data() {
    return {
      // 点击查询按钮标志
      isClickSearch: false,
      componentFlag: 'nosuodao',
      // 当前设备探测体数据列表
      sensorDataList: [],
      // 当前设备探测体数据列表 测试
      testSensorDataList: [],
      // signlar实例
      singlarInstance: null,
      // 上一个设备编码
      // equipmentCodePre: '',
      // 当前设备的设备编码
      currentEquipCode: '',
      // 设备损伤值
      equipDamageList: [],
      // 设备探测体数量
      sensorCount: 1,
      // 上一个和当前的设备编码列表索引0代表前一个，索引1代表当前的
      equipmentCodeList: ['', ''],
      //
      equipmentModel: '',
      // 当前登录的用户编码
      userCode: ''
    }
  },
  created() {
    if (getItem('equipData')) {
      this.sensorCount = getItem('equipData').sensorSerialArr
      this.equipmentCodeList[1] = getItem('equipData').equipmentCode
      this.equipmentModel = getItem('equipData').equipmentModel
      this.equipmentModel == 'IOT06' ? (this.componentFlag = 'suodao') : (this.componentFlag = 'nosuodao')
      this.isClickSearch = true
    }
    this.userCode = getItem('userData').userCode
    this.initSignlar()
    window.addEventListener('resize', this.resize)
  },

  unmounted() {
    if (getItem('equipData')) {
      removeItem('equipData')
    }
    window.removeEventListener('resize', this.resize)
  },
  // 由其他页面跳转过来，重载当前页面
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.path == '/index/detail' && from.path !== '/') {
        window.location.reload()
      }
    })
  },

  components: {
    Search,
    Overview,
    MonitorResult,
    DamageValue,
    DamageList,
    GoDistance,
    Cableway,
    MoreSensorDamageValue,
    MoreSensorOverviewVue,
    TestMonitorResult,
    TestDamageList
  },
  methods: {
    // 窗口缩放事件的监听方法
    resize() {
      if (this.isClickSearch || getItem('equipData')) {
        if (this.$refs.overViewRef) {
          this.$refs.overViewRef.myResize()
        }
        if (this.$refs.monitorRef) {
          this.$refs.monitorRef.myResize()
        }
        if (this.$refs.damageValueRef) {
          this.$refs.damageValueRef.myResize()
        }
        if (this.$refs.goDistanceRef) {
          this.$refs.goDistanceRef.myResize()
        }
        if (this.$refs.testMonitorRef) {
          this.$refs.testMonitorRef.myResize()
        }
        if (this.sensorCount >= 2) {
          for (let i = 0; i < this.sensorDataList.length; i++) {
            this.$refs[this.sensorDataList[i].monitorRef].myResize()
          }
        }
        if (this.$refs.moreSensorDamageValueRef) {
          this.$refs['moreSensorDamageValueRef'].myResize()
        }
      }
    },
    // 监听search组件设备变化时选择的设备类型，展示不同的组件
    showComponent(data) {
      let { sensorSerialArr, equipmentModel } = data.equipData
      if ((this.equipmentModel && this.equipmentModel != equipmentModel) || this.sensorCount != sensorSerialArr.length) {
        if (!getItem('equipData')) {
          this.singlarInstance.server.deregisterEquipment(this.equipmentCodeList[1])
        }
      }
      this.equipmentModel = data.equipData.equipmentModel
      if (getItem('equipData') != null) {
        this.isClickSearch = true
      } else {
        this.isClickSearch = false
      }
      this.componentFlag = data.flag
      if (data.equipData) {
        this.sensorDataList = []
        this.testSensorDataList = []
        this.sensorCount = data.equipData.sensorSerialArr.length
        let colorList = ['#9562f7', '#00B5B3', '#C09C29', '#5568C6']
        for (let i = 0; i < data.equipData.sensorSerialArr.length; i++) {
          let obj = {
            serial: data.equipData.sensorSerialArr[i],
            monitorRef: 'monitorRef' + (i + 1),
            damageListRef: 'damageListRef' + (i + 1),
            className: 'moreSensorMonitorResult' + (i + 1),
            echartContainerClass: 'chartContainer' + (i + 1),
            lineStyle: colorList[i],
            damageObj: {}
          }
          let testObj = {
            serial: data.equipData.sensorSerialArr[i],
            monitorRef: 'monitorRef' + (i + 10),
            damageListRef: 'damageListRef' + (i + 10),
            className: 'moreSensorMonitorResult' + (i + 10),
            echartContainerClass: 'chartContainer' + (i + 10),
            lineStyle: colorList[i],
            damageObj: {}
          }
          this.sensorDataList.push(obj)
          this.testSensorDataList.push(testObj)
        }
        this.getDamageValue(data.equipmentCode, data)
      }

      this.$nextTick(() => {
        if (data.flag == 'suodao') {
          this.$refs.cablewayRef.$refs.damageListRef.titleFlag = data.flag
          this.$refs.cablewayRef.$refs.damageListRef.headerCellStyle.background = 'linear-gradient(0deg, #252838, #1E2234, #242839)'
          this.$refs.cablewayRef.$refs.damageListRef.pageCount = 5
        }
      })
    },
    // 监听子组件search发出的自定义事件，获取选中的设备，然后操作子组件渲染数据
    handleSearchEmitEquipInfor(data) {
      setTimeout(() => {
        this.showComponent(data.sendToFatherInfor)
        this.$nextTick(() => {
          // 如果有前一个设备，将前一个设备注销，并初始化实时监测概览数据
          this.equipmentCodeList.push(data.currentEquipCode)
          this.equipmentCodeList.shift()
          if (this.equipmentCodeList[0]) {
            this.singlarInstance.server.deregisterEquipment(this.equipmentCodeList[0])
          }
          if (this.$refs.overViewRef) {
          }
          this.singlarInstance.server.registerEquipment(data.currentEquipCode)
          this.isClickSearch = true
          if (this.$refs.overViewRef) {
            this.$refs.overViewRef.initChartAndData()
            this.$refs.overViewRef.currentEquipInfo(data.currentEquipCode, data.currentProjectCode)
          }
          if (this.$refs.damageValueRef) {
            this.$refs.damageValueRef.currentEquipBarOptions(data.currentEquipCode)
          }
          if (this.$refs.moreSensorOverviewRef) {
            this.$refs.moreSensorOverviewRef.initOptions()
            this.$refs.moreSensorOverviewRef.getEquipInfo(data.currentEquipCode, data.currentProjectCode)
          }
          if (this.$refs.goDistanceRef) {
            this.$refs.goDistanceRef.currentEquipOptions(data.currentEquipCode)
          }
          if (this.$refs.damageListRef) {
            this.$refs.damageListRef.handleAgainRender(data)
          }
          if (this.$refs.testDamageListRef) {
            this.$refs.testDamageListRef.handleAgainRender(data)
          }
          if (this.$refs.cablewayRef) {
            this.$refs.cablewayRef.$refs.cabelOverviewRef.handleAgainRender(data)
            this.$refs.cablewayRef.$refs.monitorRef.currentEquipDamageOptions(data.currentEquipSerial[0])
            this.$refs.cablewayRef.$refs.damageListRef.handleAgainRender(data)
            this.$refs.cablewayRef.$refs.damageValueRef.currentEquipBarOptions(data.currentEquipCode)
            this.$refs.cablewayRef.$refs.goDistanceRef.currentEquipOptions(data.currentEquipCode)
          }
        })
      }, 250)
    },
    // 初始化signlar
    initSignlar() {
      $.connection.hub.logging = true
      // $.connection.hub.url = 'http://47.100.48.219:8090/signalr/hubs'

      $.connection.hub.url = signalrUrl
      // $.connection.hub.error((err) => {
      //   console.log(err);
      // })
      this.singlarInstance = $.connection.ProbeConfigWSSHub
      // 注册绑定编码器的方法
      this.singlarInstance.client.bindIoTEncode = (equipmentCode, serial, user, direction, isMoving) => {
        console.log('识别码序列号:  ' + '10进制：' + serial, ',', '16进制：' + serial.toString(16).toUpperCase())
        if (equipmentCode == this.equipmentCodeList[1]) {
          if (this.$refs.overViewRef) {
            this.$refs.overViewRef.bindEncodeDirection(equipmentCode, direction, isMoving)
          }
          if (this.$refs.cablewayRef) {
            this.$refs.cablewayRef.$refs.cabelOverviewRef.getEncodeDirection(equipmentCode, direction, isMoving)
          }
          if (this.$refs.moreSensorOverviewRef) {
            this.$refs.moreSensorOverviewRef.bindEncodeDirection(equipmentCode, direction, isMoving)
          }
        }
      }
      this.singlarInstance.client.ioT01BBindAlarmData = (equipmentCode, SerialAndID, result) => {
        if (equipmentCode == this.equipmentCodeList[1]) {
          if (this.$refs.overViewRef) {
            this.$refs.overViewRef.updateCircularGraph(equipmentCode, SerialAndID, result)
          }
          if (this.$refs.cablewayRef) {
            this.$refs.cablewayRef.$refs.cabelOverviewRef.getAlarmData(equipmentCode, SerialAndID, result)
          }
          if (this.$refs.moreSensorOverviewRef) {
            this.$refs.moreSensorOverviewRef.getAlarmData(equipmentCode, SerialAndID, result)
          }
        }
      }
      $.connection.hub
        .start()
        .done(() => {
          this.$nextTick(() => {
            if (getItem('equipData') != null) {
              let { equipmentCode } = getItem('equipData')
              this.singlarInstance.server.registerEquipment(equipmentCode)
            }
          })
        })
        .fail(function () {
          console.log('连接失败')
        })
      $.connection.hub.disconnected(e => {
        $.connection.hub.start().done(() => {
          this.singlarInstance.server.registerEquipment(this.equipmentCodeList[1])
        })
      })
    },
    // 获取设备损伤值
    async getDamageValue(equipmentCode, dataObj) {
      const res = await this.$http.post(this.$urlObj.queryEquipMonitorResult, {
        userCode: getItem('userData').userCode,
        equipmentCode: equipmentCode
      })
      if (res.status == 200) {
        let data = res.data.data.damageInfoArr
        for (let i = 0; i < data.length; i++) {
          let obj = {
            damageLocationArr: data[i].damageLocationArr,
            encodeWidth: res.data.data.encodeWidth,
            monitorMaxlength: data[i].monitorMaxlength,
            cableName: data[i].cableName.slice(0, 1) == '0' ? data[i].serial : data[i].cableName + '-' + data[i].serial
          }
          let testObj = {
            damageLocationArr: data[i].sampleValues,
            encodeWidth: res.data.data.encodeWidth,
            monitorMaxlength: data[i].monitorMaxlength,
            cableName: data[i].cableName.slice(0, 1) == '0' ? data[i].serial : data[i].cableName + '-' + data[i].serial
          }
          this.sensorDataList[i].damageObj = { ...obj }
          this.testSensorDataList[i].damageObj = { ...testObj }
        }
        if (this.sensorCount >= 2) {
          for (let i = 0; i < this.sensorDataList.length; i++) {
            if (JSON.stringify(this.sensorDataList[i].damageObj) != '{}') {
              if (this.isClickSearch) {
                this.$refs[this.sensorDataList[i].monitorRef].renderEchart(this.sensorDataList[i].damageObj)
                this.$refs[this.sensorDataList[i].damageListRef].renderDamageList(this.sensorDataList[i].damageObj)
              }
            }
            if (JSON.stringify(this.testSensorDataList[i].damageObj) != '{}') {
              if (this.isClickSearch) {
                if (this.userCode == '999999') {
                  this.$refs[this.testSensorDataList[i].monitorRef].renderEchart(this.testSensorDataList[i].damageObj)
                  this.$refs[this.testSensorDataList[i].damageListRef].renderDamageList(this.testSensorDataList[i].damageObj)
                }
              }
            }
          }
          if (this.isClickSearch) {
            // this.$refs['goDistanceRef'].currentEquipOptions(dataObj.equipmentCode || dataObj.currentEquipCode)
            this.$refs['moreSensorDamageValueRef'].getDamageValue(dataObj.equipmentCode || dataObj.currentEquipCode)
          }
        } else {
          if (this.$refs.damageListRef) {
            if (this.isClickSearch) {
              this.$refs.damageListRef.renderDamageList(this.sensorDataList[0].damageObj)
            }
          }
          if (this.$refs.testDamageListRef) {
            if (this.isClickSearch) {
              this.$refs.testDamageListRef.renderDamageList(this.testSensorDataList[0].damageObj)
            }
          }

          if (this.$refs.monitorRef) {
            if (this.isClickSearch) {
              this.$refs.monitorRef.renderEchart(this.sensorDataList[0].damageObj)
            }
          }
          if (this.$refs.testMonitorRef) {
            if (this.isClickSearch) {
              this.$refs.testMonitorRef.renderEchart(this.testSensorDataList[0].damageObj)
            }
          }

          if (this.$refs.cablewayRef) {
            this.$refs.cablewayRef.$refs.damageListRef.handleAgainRender(dataObj)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.searchRow {
  width: 100%;
  margin-top: 20px;
}

.mainRow {
  width: 100%;
  margin-top: 20px;
  min-height: 275px;

  .moreSensorMonitorResult {
    .el-col {
      height: 380px;
    }
  }

  .moreSensorMonitorResult1 {
    margin-top: 20px;
    padding-right: 1px;
  }

  .moreSensorMonitorResult2 {
    margin-top: 20px;
    padding-left: 1px;
  }

  .moreSensorMonitorResult3 {
    margin-top: 2px;
    padding-right: 1px;
  }

  .moreSensorMonitorResult4 {
    margin-top: 2px;
    padding-left: 1px;
  }

  .overviewCol {
    width: 100%;
    height: 640px;
  }

  @media screen and (min-width: 1200px) {
    .overviewCol {
      // height: 500px;
      height: 400px;
      padding-right: 10px;
    }
  }

  .moreSensorOverviewCol {
    height: 630px;
  }

  @media screen and (min-width: 1200px) {
    .moreSensorOverviewCol {
      // height: 520px;
      height: 500px;
    }
  }

  .monitorCol {
    width: 100%;
    height: 390px;
    margin-top: 20px;
  }

  @media screen and (min-width: 1200px) {
    .monitorCol {
      // height: 500px;
      height: 400px;
      margin-top: 0;
      padding-left: 10px;
    }
  }

  .damageValueCol {
    width: 100%;
    height: 420px;
    margin-top: 20px;
  }

  @media screen and (min-width: 1200px) {
    .damageValueCol {
      height: 450px;
      padding-right: 5px;
    }
  }

  @media screen and (min-width: 1500px) {
    .damageValueCol {
      padding-right: 10px;
    }
  }

  .goDistanceCol {
    width: 100%;
    height: 490px;
    margin-top: 20px;
  }

  @media screen and (min-width: 1200px) {
    .goDistanceCol {
      height: 450px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  @media screen and (min-width: 1500px) {
    .goDistanceCol {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .goDistanceColMoreSensor {
    width: 100%;
    // height: 520px;
    height: 500px;
    margin-top: 20px;
  }

  @media screen and (min-width: 1200px) {
    .goDistanceColMoreSensor {
      padding-left: 10px;
      margin-top: 0;
    }
  }

  @media screen and (min-width: 1500px) {
    .goDistanceColMoreSensor {
      padding-left: 10px;
      margin-top: 0;
    }
  }

  .moreSensorDamageValue {
    width: 100%;
    height: 450px;
    margin-top: 20px;
    margin-bottom: 20px;
    background: #1c1f30;
  }

  .damageListCol {
    width: 100%;
    height: 592px;
    margin-top: 20px;
  }

  @media screen and (min-width: 1200px) {
    .damageListCol {
      height: 450px;
      padding-left: 5px;
    }
  }

  @media screen and (min-width: 1500px) {
    .damageListCol {
      padding-left: 10px;
    }
  }

  .rightCol {
    width: 100%;
    height: 100%;
    padding-left: 1.074%;
  }
}

.testRow {
  width: 100%;
  height: 400px;

  .el-row {
    height: 100%;
  }
}

.downRow {
  width: 100%;
  height: 41.66%;
  margin-top: 1.04%;
  min-height: 317px;

  .el-col:nth-child(2) {
    padding-left: 1.074%;
  }

  .el-col:nth-child(3) {
    padding-left: 1.074%;
  }
}

/* 搜索的输入框颜色 */
:deep(.el-input__inner) {
  background: #33394e !important;
  border: 0px !important;
}

/* 总条目字体颜色 */
:deep(.el-pagination__total) {
  color: #fff !important;
  margin-right: 0;
}

:deep(.btn-prev) {
  margin: 0 !important;
}

:deep(.btn-next) {
  margin: 0 !important;
}

:deep(.el-pager .number) {
  margin: 0 !important;
}

/* 当前按钮的背景色和字体颜色 */
:deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
  background-color: #1c1f30;
  color: #0099ff;
}

/* 没有选中的按钮的背景色和字体颜色 */
:deep(.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li) {
  background-color: #1c1f30;
  color: #fff;
}

:deep(.el-table__body) {
  border-left: 1px solid #292b3d;
  border-right: 1px solid #292b3d;
  box-sizing: border-box;
}

/* 去除表格底部的白线 */
:deep(.el-table::before) {
  height: 0px;
}

:deep(.el-table, .el-table__expanded-cell) {
  background-color: #1c1f30;
}
</style>
