<template>
  <div class="moreSensorDamageValueRootWrap">
    <div id="moreSensorContainer"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { getItem } from '../../api'
const unwarp = obj => obj && (obj.__v_raw || obj.valueOf() || obj)
export default {
  name: 'MoreSensorDamageValue',
  data() {
    return {
      // 图表数据
      echartOptions: {},
      //   echarts实例
      echartInstance: null
    }
  },
  mounted() {
    // 初始化图表数据
    this.initOptions()
  },
  unmounted() {
    this.echartInstance && this.echartInstance.dispose()
  },
  methods: {
    myResize() {
      this.echartInstance.resize()
    },
    // 初始化图表数据
    initOptions() {
      this.echartOptions = {
        title: {
          text: '损伤量值分布',
          left: 10,
          top: 20,
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'axis'
        },

        grid: {
          right: '95px',
          bottom: '28px',
          left: '30px',
          top: '20%'
        },
        toolbox: {
          top: 40,
          right: 50,
          feature: {
            dataZoom: { show: true }
          }
        },
        legend: {
          top: 50,
          right: 186,
          itemWidth: 18,
          itemHeight: 7,
          textStyle: {
            color: '#fff'
          },
          data: []
        },
        xAxis: [
          {
            type: 'category',
            name: '损伤区间(%)',
            nameGap: 20,
            nameLocation: 'end',
            axisLine: {
              show: false
            },
            axisLabel: {
              show: true,
              color: '#B9B9B9' //更改坐标轴文字颜色
            },
            nameTextStyle: {
              //   padding: [0,0,0,678]
              color: '#fff'
            },
            axisTick: {
              show: false,
              alignWithLabel: true
            },
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: 'f (次数)',
            min: 0,
            // max: 40,
            position: 'left',
            nameGap: 16,
            nameTextStyle: {
              color: '#fff'
            },
            // splitNumber: 10,
            axisLine: {
              show: false,
              lineStyle: {
                color: '#B9B9B9'
              }
            },
            splitLine: {
              //分割线配置
              show: true,
              lineStyle: {
                color: '#292B3D'
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
          }
        ],
        series: []
      }
    },
    // 渲染图表
    async renderEchart(equipmentCode) {
      const res = await this.$http.post(this.$urlObj.queryDamageCount, {
        userCode: getItem('userData').userCode,
        equipmentCode: equipmentCode
      })
      if (res.status == 200 && res.data.resultCode == '0000') {
        this.echartOptions.series = []
        this.echartOptions.xAxis.data = []
        let data = res.data.data
        let colorArr = ['#8355DD', '#00B5B3', '#C09C29', '#5568C6']
        for (let i = 0; i < data.length; i++) {
          let xData = []
          let yData = []
          let obj = {
            type: 'bar',
            name: data[i].serialHexString,
            yAxisIndex: 0,
            barWidth: 7,
            data: [],
            itemStyle: {
              color: colorArr[i]
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#CCCCCC'
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
          }
          for (let j = 0; j < data[i].damageCountArr.length; j++) {
            if (j < data[i].damageCountArr.length - j) {
              xData.push(data[i].damageCountArr[j].damageScope + '-' + data[i].damageCountArr[j + 1].damageScope)
            } else {
              xData.push(data[i].damageCountArr[j].damageScope + '-' + ((1.0 + data[i].damageCountArr.length / 2).toFixed(1) + '%'))
            }

            yData.push(data[i].damageCountArr[j].damageCount)
          }
          this.echartOptions.legend.data[i] = { name: data[i].serialHexString }
          obj.data = yData
          this.echartOptions.xAxis[0].data = xData
          this.echartOptions.series.push(obj)
        }

        this.$nextTick(() => {
          let dom = document.getElementById('moreSensorContainer')
          if (this.echartInstance) this.echartInstance.dispose()
          this.echartInstance = echarts.init(dom)
          this.echartOptions && unwarp(this.echartInstance).setOption(this.echartOptions)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.moreSensorDamageValueRootWrap {
  width: 100%;
  height: 100%;
  #moreSensorContainer {
    width: 100%;
    height: 100%;
  }
}
</style>
