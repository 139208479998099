<template>
  <el-row>
    <el-col style="height:100%;" :span="24">
      <div class="monitorWrap">
        <div class="title">
          <h4>{{ componentTitle }}</h4>
        </div>
        <div :class="echartContainerClass"></div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { getItem } from '../../api/index'
import { eventBus } from '@ai-zen/event-bus'
import * as echarts from 'echarts'
const unwarp = obj => obj && (obj.__v_raw || obj.valueOf() || obj)
export default {
  name: 'testMonitorResult',
  data() {
    return {
      // 设备损伤结果数据
      damageResultOptions: '',
      // 图表容器
      container: null,
      // X轴的值
      xData: [],
      // y轴的值
      yData: []
    }
  },
  props: {
    componentTitle: {
      type: String,
      default: ''
    },
    // echarts容器类名
    echartContainerClass: String,
    // 图像数据线条的颜色
    lineStyle: {
      type: String,
      default: '#9562f7'
    },
    // 识别码序列号
    serial: String,
    // 设备损伤数据对象
    damageObj: {
      type: Object,
      default: {}
    },
    // 查询按钮点击标志
    isClickSearch: {
      type: Boolean,
      default: true
    }
  },
  created() {
    // 初始化图表数据
    this.damageResultOptions = this.monitorDatamInit()
    // 更新设备损伤监测结果数据
    // this.getEquipDamageOptions()
  },
  mounted() {
    let copythis = this
    this.$nextTick(() => {
      let chartContainer = document.getElementsByClassName(this.echartContainerClass)[0]
      chartContainer && (this.container = echarts.init(chartContainer))
    })
  },
  watch: {
    // damageObj: {
    //   handler() {
    //     console.log('change')
    //     if (Object.keys(this.damageObj).length > 0 && this.isClickSearch) {
    //       this.renderEchart(this.damageObj)
    //     }
    //   },
    //   deep: true
    // }
  },
  methods: {
    myResize() {
      this.container.resize()
    },
    // 初始化损伤结果数据
    monitorDatamInit() {
      let obj = {
        xAxis: {
          type: 'category',
          name: '损伤位置(cm)',
          nameTextStyle: {
            color: '#FFFFFF',
            fontSize: 14
            // padding: [0,0,0,1040]
          },
          nameGap: 5,
          data: this.xData,
          axisLabel: {
            color: '#B9B9B9'
          },
          nameLocation: 'end',
          axisLine: {
            lineStyle: {
              color: '#292B3D'
            }
          },
          axisTick: {
            show: true
          }
        },

        yAxis: {
          type: 'value',
          name: '损伤值（%）',
          nameTextStyle: {
            color: '#FFFFFF',
            fontSize: 14,
            padding: [0, 0, 0, 10]
          },
          axisLabel: {
            interval: 5,
            formatter: '{value} %',
            color: '#B9B9B9'
          },
          splitLine: {
            //分割线配置
            show: true,
            lineStyle: {
              color: '#292B3D'
            }
          },
          max: 50
        },

        grid: {
          top: '30px',
          left: '50px',
          right: '90px',
          bottom: '20px'
        },
        series: [
          {
            data: this.yData,
            name: this.serial,
            type: 'line',
            smooth: true,
            symbol: 'none',
            color: this.lineStyle,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(129, 67, 170, 0.4)'
                },
                {
                  offset: 1,
                  color: 'rgba(28, 31, 48, 0.4)'
                }
              ])
            }
          }
        ],
        tooltip: {
          show: true,
          trigger: 'axis',
          axisPointer: {
            type: 'line'
          },
          formatter: '损伤位置:{b0}<br />损伤值:{c0}'
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            dataView: {
              show: false,
              readOnly: false
            },
            magicType: {
              // type: ["line", "bar"]
            }
            // restore: {},
            // saveAsImage: {}
          }
        }
      }
      if (this.serial) {
        obj.legend = {
          data: [
            {
              name: this.serial,
              textStyle: {
                color: '#fff'
              }
            }
          ]
        }
      }
      return obj
    },

    // 获取从全局概览界面跳转过来的设备的损伤值
    getEquipDamageOptions() {
      if (getItem('equipData')) {
        let data = getItem('equipData')
        if (data.equipmentModel == 'IOT06') {
          // 请求数据
          this.getDamageValue(data.sensorSerialArr[0])
        }
        if (data.equipmentModel == 'IOT01B') {
          // 请求数据
          let equipmentCode = getItem('equipData').equipmentCode
          this.getDamageValue(equipmentCode)
        }
      }
    },

    // 获取当前设备损伤值
    currentEquipDamageOptions(equipmentCode) {
      this.xData = []
      this.yData = []
      // 销毁echarts实例
      this.container && this.container.dispose()
      // 请求数据
      this.getDamageValue(equipmentCode)
    },

    // 获取设备损伤值
    async getDamageValue(equipmentCode) {
      if (equipmentCode.length <= 8) {
        if (this.isClickSearch) {
          const res = await this.$http.post(this.$urlObj.queryLatestDamage, {
            userCode: getItem('userData').userCode,
            serial: equipmentCode
          })
          if (res.status == 200 && res.data.resultCode == '0000') {
            if (res.data.data) {
              let result = res.data.data.result
              let arr = new Array(result.length).fill(0)
              let position = ''
              for (let i = 0; i < result.length; i++) {
                position = i
                arr[position] = result[i] < 10 ? 0 : result[i] / 10
                for (let i = position - 1; i > 0; i++) {
                  arr[i] = Math.max(arr[i], arr[i + 1] >> 1)
                  if (arr[i] <= 1) break
                }
                for (let i = position + 1; i < result.length; i++) {
                  arr[i] = Math.max(arr[i], arr[i - 1] >> 1)
                  if (arr[i] <= 1) break
                }
              }

              for (let j = 0; j < arr.length; j++) {
                this.xData.push(j)
                this.yData.push(arr[j])
              }
              this.damageResultOptions.xAxis.data = this.xData
              this.damageResultOptions.series[0].data = this.yData
              this.$nextTick(() => {
                let dom = document.getElementsByClassName(this.echartContainerClass)[0]
                // 重新初始化echarts实例
                this.container = echarts.init(dom)
                // this.damageResultOptions && unwarp(this.container).setOption(this.damageResultOptions);
                unwarp(this.container).setOption(this.damageResultOptions)
              })
            }
          }
        }
      } else {
        const res = await this.$http.post(this.$urlObj.queryEquipMonitorResult, {
          userCode: getItem('userData').userCode,
          equipmentCode: equipmentCode
        })
        if (res.status === 200) {
          let data = res.data.data
          let obj = {
            encodeWidth: data.encodeWidth,
            monitorMaxlength: data.damageInfoArr[0].monitorMaxlength,
            damageLocationArr: data.damageInfoArr[0].sampleValues
          }
          this.renderEchart(obj)
        }
      }
    },

    // 渲染非索道设备的实时监测结果图形
    renderEchart(data) {
      if (JSON.stringify(data) != '{}') {
        this.damageResultOptions.xAxis.data = []
        this.damageResultOptions.series[0].data = []
        this.xData = []
        this.yData = []
        // 有损伤值的位置两侧，进行损伤值衰减
        // let arr = new Array(data.monitorMaxlength).fill(0)
        // let position = ''
        // for (let i = 0; i < data.damageLocationArr.length; i++) {
        //   position = i
        //   arr[position] = data.damageLocationArr[i] / 10
        //   for (let i = position - 1; i > 0; i++) {
        //     arr[i] = Math.max(arr[i], arr[i + 1] >> 1)
        //     if (arr[i] <= 1) break
        //   }
        //   for (let i = position + 1; i < data.monitorMaxlength; i++) {
        //     arr[i] = Math.max(arr[i], arr[i - 1] >> 1)
        //     if (arr[i] <= 1) break
        //   }
        // }
        for (let i = 0; i < data.damageLocationArr.length; i++) {
          this.xData.push(Number((i * data.encodeWidth).toFixed(1)))
          this.yData.push(data.damageLocationArr[i] / 10)
        }
        this.damageResultOptions.xAxis.data = this.xData
        this.damageResultOptions.series[0].data = this.yData
        this.damageResultOptions.series[0].name = data.cableName
        if (this.damageResultOptions.legend) {
          this.damageResultOptions.legend.data[0].name = data.cableName
        }

        this.$nextTick(() => {
          let dom = document.getElementsByClassName(this.echartContainerClass)[0]
          if (this.container) {
            this.container.dispose()
            this.container = null
          }
          // 重新初始化echarts实例
          this.container = echarts.init(dom)
          unwarp(this.container).setOption(this.damageResultOptions)
        })
      } else {
        if (this.container) {
          this.damageResultOptions.xAxis.data = []
          this.damageResultOptions.series[0].data = []
          this.xData = []
          this.yData = []
          this.$nextTick(() => {
            let dom = document.getElementsByClassName(this.echartContainerClass)[0]
            this.container && this.container.dispose()
            // 重新初始化echarts实例
            this.container = echarts.init(dom)
            unwarp(this.container).setOption(this.damageResultOptions)
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-row {
  width: 100%;
  height: 100%;
  .monitorWrap {
    width: 100%;
    height: 100%;
    background-color: #1c1f30;
    box-sizing: border-box;
    padding-top: 18px;
    .title {
      padding-left: 2.45%;
      font-size: 16px;
      font-family: Microsoft YaHei;
      color: #ffffff;
      h4 {
        font-size: 18px;
        height: 20px;
      }
    }
    .chartContainer,
    .chartContainer1,
    .chartContainer2,
    .chartContainer3,
    .chartContainer4,
    .chartContainer10,
    .chartContainer11 {
      width: 100%;
      height: 300px;
      background-color: #1c1f30;
      // padding-top: 2.75%;
      margin-top: 20px;
      box-sizing: border-box;
    }
    .testChartContainer {
      width: 100%;
      height: 500px;
      background-color: #1c1f30;
      // padding-top: 2.75%;
      margin-top: 20px;
      box-sizing: border-box;
    }
  }
  @media screen and (min-width: 1200px) {
    .monitorWrap {
      padding-top: 18px;
      .chartContainer {
        margin-top: 20px;
      }
    }
  }
}
</style>
