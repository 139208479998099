<template>
  <div></div>
</template>

<script>
export default {
  name: "SensorEncodeInfor",
};
</script>

<style>
</style>