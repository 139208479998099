<template>
  <div class="moreSensorOverviewRootWrap">
    <div class="left">
      <div class="title">实时监测概览</div>
      <table cellspacing="0">
        <thead>
          <tr>
            <th colspan="2">
              {{ typeof equipDetailInfo.projectName == undefined ? '' : equipDetailInfo.projectName }}
            </th>
          </tr>
        </thead>
        <tbody class="tbody">
          <tr>
            <td>
              设备:
              {{ typeof equipDetailInfo.equipmentName == undefined ? '' : equipDetailInfo.equipmentName }}
            </td>
            <td v-if="equipDetailInfo.workingStatus != undefined && equipDetailInfo.workingStatus == 1">
              工作状态: 离线
            </td>
            <td v-if="equipDetailInfo.workingStatus != undefined && equipDetailInfo.workingStatus == 2">
              工作状态: 工作
            </td>
            <td v-if="equipDetailInfo.workingStatus != undefined && equipDetailInfo.workingStatus == 3">
              工作状态: 待机
            </td>
            <td v-if="equipDetailInfo.workingStatus != undefined && equipDetailInfo.workingStatus == 4">
              工作状态: 停用
            </td>
            <td v-if="equipDetailInfo.workingStatus != undefined && equipDetailInfo.workingStatus == 5">
              工作状态: {{ equipDetailInfo.faultProbeInfo ? (equipDetailInfo.faultProbeInfo.faultProbeModel == 'ENCODE' ? '编码器故障' : '探测体故障') : '' }}
            </td>
            <td v-if="equipDetailInfo.workingStatus != undefined && equipDetailInfo.workingStatus == 6">
              工作状态: 报警
            </td>
            <td v-if="equipDetailInfo.workingStatus == undefined">
              工作状态:
            </td>
          </tr>
          <tr>
            <td
              class="sensorTd"
              :title="
                equipDetailInfo.serials &&
                  equipDetailInfo.serials
                    .split('、')
                    .filter(item => item.slice(0, 1) == '0')
                    .join('、')
              "
            >
              探测体:
              {{
                equipDetailInfo.serials &&
                  equipDetailInfo.serials
                    .split('、')
                    .filter(item => item.slice(0, 1) == '0')
                    .join('、')
              }}
            </td>
            <td>
              编码器:
              {{
                equipDetailInfo.serials &&
                  equipDetailInfo.serials
                    .split('、')
                    .filter(item => item.slice(0, 1) == '1')
                    .join('')
              }}
            </td>
          </tr>
          <tr>
            <td>安装时间：{{ equipDetailInfo.installDate && new Date(equipDetailInfo.installDate).toLocaleDateString().replaceAll('/', '-') }}</td>
            <td>到期时间：{{ equipDetailInfo.validTime && new Date(equipDetailInfo.validTime).toLocaleDateString().replaceAll('/', '-') }}</td>
          </tr>
          <tr>
            <td v-if="equipDetailInfo.riskLevel == 0">风险程度: 健康</td>
            <td v-else-if="equipDetailInfo.riskLevel == 1">
              风险程度: 轻度
            </td>
            <td v-else-if="equipDetailInfo.riskLevel == 2">
              风险程度: 中度
            </td>
            <td v-else-if="equipDetailInfo.riskLevel == 3">
              风险程度: 重度
            </td>
            <td v-else-if="equipDetailInfo.riskLevel == 4">
              风险程度: 超限
            </td>
            <td v-else>风险程度:</td>
            <td>报警：否</td>
          </tr>
          <tr>
            <td>
              平均损伤值:
              {{ typeof equipDetailInfo.avgDamage == 'undefined' ? '' : equipDetailInfo.avgDamage }}
            </td>
            <td>
              损伤个数:
              {{ typeof equipDetailInfo.damageCount == 'undefined' ? '' : equipDetailInfo.damageCount + '个' }}
            </td>
          </tr>
          <!-- <tr>
            <td>断丝：{{ workState }}</td>
            <td>磨损：{{ workState }}</td>
          </tr>
          <tr>
            <td>疲劳：{{ workState }}</td>
            <td>锈蚀：{{ workState }}</td>
          </tr>
          <tr>
            <td colspan="2">变形松股：{{ workState }}</td>
          </tr> -->
          <tr>
            <td>
              当日运行时长:
              {{ typeof equipDetailInfo.runningTime == 'undefined' ? '' : equipDetailInfo.runningTime + '小时' }}
            </td>
            <td>
              当日监测里程:
              {{ typeof equipDetailInfo.runningDistance == 'undefined' ? '' : equipDetailInfo.runningDistance + 'km' }}
            </td>
          </tr>

          <tr>
            <td :title="equipDetailInfo.installLocation" class="installLocationTd">安装地址: {{ equipDetailInfo.installLocation }}</td>
            <td>运行状态：{{ workState }}</td>
          </tr>

          <tr>
            <td style="color:#0099ff" colspan="2">
              责任人:
              {{ typeof equipDetailInfo.contactPerson == 'undefined' ? '' : equipDetailInfo.contactPerson }}
            </td>
          </tr>
        </tbody>
        <tbody class="locationBody">
          <tr>
            <p style="padding:4px 0 4px 4px">风险损伤位置：</p>
          </tr>
          <tr v-for="(item1, index1) in damageLocationArr" :key="index1">
            <td :colspan="item1.length == 1 ? 2 : 1" :class="{ locationTd: index1 == 0 ? true : false }" v-for="(item2, index2) in item1" :key="index2">
              {{ item2.sensorId + ':' + item2.damageLocation + 'cm' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="right">
      <div class="top">
        <div class="barWrap" v-for="item in sensorDataArr" :key="item">
          <div>{{ item }}</div>
          <div class="barAnimationWrap"><bar-animation-vue :ref="item" :percentData="item == sensorData[0] ? sensorData[1] : 0"></bar-animation-vue></div>
        </div>
      </div>
      <div class="down">
        <div class="imgWrap">
          <img :src="imgSrc" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getItem } from '../../api'
import BarAnimationVue from '../common/BarAnimation.vue'
export default {
  name: 'MoreSensorOverview',
  data() {
    return {
      // 设备信息
      equipDetailInfo: {},
      //   设备工作状态
      workState: '',
      // 钢丝绳运行方向图的url
      imgSrc: require('../../assets/image/noState.png'),
      //   探测体数据
      sensorData: [],
      timer: null
    }
  },
  components: {
    BarAnimationVue
  },
  computed: {
    damageLocationArr() {
      let arr = this.equipDetailInfo.damageLocationArr
      let arr1 = []
      if (arr) {
        for (let i = 0; i < arr.length; i += 2) {
          arr1.push(arr.slice(i, i + 2))
        }
        return arr1
      } else {
        return []
      }
    },
    sensorDataArr() {
      let { serials } = this.equipDetailInfo
      if (serials) {
        let sensorArr = serials.split('、').filter(item => item[0] == 0)
        return sensorArr
      } else {
        return []
      }
    }
  },
  mounted() {
    if (getItem('equipData') && JSON.stringify(getItem('equipData')) != '{}') {
      let { equipmentCode, projectCode } = getItem('equipData')
      this.getEquipInfo(equipmentCode, projectCode)
    }
  },
  unmounted() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  },
  methods: {
    // 获取设备信息的方法
    async getEquipInfo(equipmentCode, projectCode) {
      const res = await this.$http.post(this.$urlObj.queryRealtimeOverview, {
        userCode: getItem('userData').userCode,
        projectCode: projectCode,
        equipmentCode: equipmentCode
      })
      if (res.status === 200 && res.data.resultCode == '0000') {
        this.equipDetailInfo = res.data.data
        let { personalityConfig } = getItem('userData')
        let onLineArr = []
        if (personalityConfig.length) {
          personalityConfig.forEach(item => {
            if (item.configType == 'EquipmentOnLine') {
              onLineArr.push(item)
            }
          })
        }
        let currentTimeStamp = new Date().getTime()
        let standbyList = ['义蓬5架桥机', '党湾80架桥机', '萧山2号架桥机', '马鞍山2架桥机']
        onLineArr.forEach(item => {
          let isInArr = equipmentCode == item.userPersonalityConfig.equipmentCode
          let isValid = new Date(item.userPersonalityConfig.invalidTime + ' 23:59:59').getTime() > currentTimeStamp
          if (isInArr && isValid) {
            this.equipDetailInfo.workingStatus = 2
          }
          // 杭绍甬高速特殊需求开始
          if (getItem('userData').userCode == '848846') {
            if (standbyList.includes(this.equipDetailInfo.equipmentName)) {
              this.equipDetailInfo.workingStatus = 3
            }
          }
          // 杭绍甬高速特殊需求结束
        })
      }
    },
    // 获取探测体损伤值的方法，父组件调用
    getAlarmData(equipmentCode, SerialAndID, result) {
      let str = SerialAndID
      let hexStr = str.toString(16)
      let key = hexStr
      for (let i = 0; i < 8 - hexStr.length; i++) {
        key = '0' + key
      }
      this.sensorData = [key.toUpperCase(), result / 10]
    },

    // 绑定编码器方向的函数，用来指定钢丝绳方向
    bindEncodeDirection(equipmentCode, direction, isMoving) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.workState = ''
        this.imgSrc = require('../../assets/image/noState.png')
      }, 20 * 1000)
      if (isMoving == 0) {
        this.workState = '停止'
        this.imgSrc = require('../../assets/image/stop.gif')
      }
      if (isMoving != 0 && direction == 0) {
        this.workState = '下降'
        this.imgSrc = require('../../assets/image/drop.gif')
      }
      if (isMoving != 0 && direction == 1) {
        this.workState = '上升'
        this.imgSrc = require('../../assets/image/rise.gif')
      }
    },
    // 初始化数据
    initOptions() {
      // 设备信息
      this.equipDetailInfo = {}
      //   设备工作状态
      this.workState = ''

      // 钢丝绳运行方向图的url
      this.imgSrc = require('../../assets/image/noState.png')

      //   探测体数据
      this.sensorData = []
    },
    // 模拟多个测点的探测体数据
    simulateSensorData() {
      setInterval(() => {
        let key = Math.random(0, 1) < 0.5 ? this.sensorDataArr[0] : this.sensorDataArr[1]
        let result = Math.random(1, 40)
        this.sensorData = [key, result / 10]
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.moreSensorOverviewRootWrap {
  width: 100%;
  height: 100%;
  background-color: #1c1f30;
  position: relative;
  .left {
    color: #fff;
    width: 100%;
    padding-top: 60px;
    box-sizing: border-box;
    .title {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      position: absolute;
      left: 20px;
      top: 20px;
    }
    table {
      width: 98%;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
    thead {
      background-color: #292b3d;
      width: 100%;
      tr {
        width: 100%;
        th {
          width: 50%;
          height: 32px;
          color: #fefefe;
        }
      }
    }
    tbody {
      width: 100%;

      tr {
        td {
          width: 50%;
          padding: 4px 0 4px 4px;
          border-right: 1px solid #292b3d;
          border-bottom: 1px solid #292b3d;
        }
        td:nth-child(1) {
          border-left: 1px solid #292b3d;
        }
      }
    }
    .locationBody {
      tr:nth-child(1) td {
        border-top: 1px solid #292b3d;
      }
    }
    .sensorTd,
    .installLocationTd {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  @media screen and (min-width: 1200px) {
    .left {
      width: 63.88%;
      height: 100%;
    }
  }
  .right {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-top: 10px;
    .top {
      width: 50%;
      height: 50%;
      display: flex;
      padding-right: 10px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .barWrap {
        width: 100%;
        max-width: 300px;
        display: flex;
        justify-content: space-around;
        margin: 10px 0;
        color: #fff;
        .barAnimationWrap {
          width: 50%;
          height: 20px;
          position: relative;
        }
      }
    }
    @media screen and (min-width: 1200px) {
      .top {
        width: 100%;
      }
    }
    .down {
      width: 50%;
      height: 50%;
      display: flex;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #393c51;
      .imgWrap {
        width: 70%;
        max-width: 150px;
      }
    }
    @media screen and (min-width: 1200px) {
      .down {
        border-left: none;
        width: 100%;
        border-top: 1px solid #393c51;
        box-sizing: border-box;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .right {
      width: 36.12%;
      height: 100%;
      flex-direction: column;
      border-left: 1px solid #393c51;
      margin-top: 0;
    }
  }
  .locationTd {
    border-top: 1px solid #292b3d;
  }
}
@media screen and (min-width: 1200px) {
  .moreSensorOverviewRootWrap {
    display: flex;
  }
}
</style>
